import { useRef, useState, useEffect } from "react";
import { AnimatePresence, motion } from 'framer-motion';
import { getImage } from '../utils/cockpit';
import LazyloadImage from "../components/LazyloadImage";
import StandardLoadImage from "../components/StandardLoadImage";
import ReactMarkdown from "react-markdown";

const LandingPage = (props) => {

  const { landingPageData, windowDimensions } = props;
  const [visibleFrame, setVisibleFrame] = useState(0);
  const { windowWidth, windowHeight } = windowDimensions;
  const [allFramesAreLoaded, setAllFramesAreLoaded] = useState(false);

  const section = useRef();

  const animateFrames = (e) => {
    let x = e.clientX;
    if (!x) {
      if (e.touches) {
        if (e.touches[0]) {
          if (e.touches[0].clientX) {
            x = e.touches[0].clientX;
          }
        }
      }
    }
    if (x && allFramesAreLoaded === true) {
      const framesLength = landingPageData.frame_animation.length;
      const frame = Math.floor((framesLength / windowWidth) * x);
      setVisibleFrame(frame);
    }
  }

  const handleMouseMove = (e) => {
    if (landingPageData.frame_animation) {
      if (landingPageData.frame_animation[1]) {
        animateFrames(e);
      }
    }
  }

  useEffect(() => {
    let loadedCount = 0;

    const handleImgLoad = () => {
      if (landingPageData.frame_animation) {
        if (landingPageData.frame_animation[0]) {
          loadedCount++;
          if (loadedCount === landingPageData.frame_animation.length) {
            setAllFramesAreLoaded(true);
          }
        }
      }
    }

    const handleGetImage = (url) => {
      const img = document.createElement('img');
      img.addEventListener('load', handleImgLoad);
      img.src = url;
    }

    if (landingPageData.frame_animation) {
      if (landingPageData.frame_animation[0]) {
        for (let frame of landingPageData.frame_animation) {
          getImage(frame.path, 1200, 1200, 60, handleGetImage);
        }
      }
    }
  }, [landingPageData.frame_animation]);

  const animation = useRef();

  return (
    <div
      ref={section}
      className={`section section--landing${props.menuIsOpen === true ? ' section--menu-is-open' : ''}`}
      style={{
        height: window.innerHeight * 1.5 + 'px'
      }}
      onMouseMove={handleMouseMove}
      onTouchMove={handleMouseMove}
    >
      <div
        className="section--landing__credit__wrapper"
        style={{
          top: windowHeight - 60 + 'px'
        }}
      >
        <p className="credit font--wotfard">Website developed by <a target="_blank" rel="noreferrer" href="https://dxr.zone/">DXR Zone</a></p>
      </div>
    {
      landingPageData.image_parallax_behind &&
      <div className="landing-page__parallax landing-page__parallax--back">
        <div className="landing-page__parallax__inner">
          <LazyloadImage {...props} image={landingPageData.image_parallax_behind} />
        </div>
      </div>
      }
    {
      landingPageData.image_parallax_in_front &&
      <div className="landing-page__parallax landing-page__parallax--front">
        <div className="landing-page__parallax__inner">
          <LazyloadImage {...props} image={landingPageData.image_parallax_in_front} />
        </div>
        </div>
      }
      <AnimatePresence>
        {
          props.location.pathname === '/' && windowWidth >= 768 &&
          <motion.div
            drag
            dragConstraints={{
              top: 0 - windowHeight * 0.5,
              bottom: animation.current ? windowHeight * 0.5 - animation.current.offsetHeight : windowHeight * 0.5,
              left: 0 - windowWidth * 0.2,
              right: animation.current ? windowWidth * 0.8 - animation.current.offsetWidth : windowWidth * 0.8
            }}
            dragElastic={false}
            dragMomentum={false}
            style={{
              left: windowWidth * 0.2 + 'px',
              top: windowHeight * 0.5 + 'px'
            }}
            initial={{
              scale: 0.8
            }}
            animate={{
              scale: 1
            }}
            ref={animation}
            className="landing-page__parallax--animation modal"
          >
            <div className="modal__inner">
              <div className="landing-page__parallax__inner">
                <div className="modal__header modal--folder__header">
                  <div className="modal__header__inner">
                    <div className="modal__header__buttons">
                      <button
                        className="folder__header__close modal__header__button modal__header__button--close"
                        onClick={() => { }}
                      />
                      <button
                        className="folder__header__minimise modal__header__button modal__header__button--minimise"
                        onClick={() => { }}
                      />
                      <button
                        className="folder__header__fullscreen modal__header__button modal__header__button--fullscreen"
                        onClick={() => { }}
                      />
                    </div>
                    <h2 className="modal__header__title">{`TEYOSH ${new Date().getFullYear()}`}</h2>
                  </div>
                </div>
                <div className="modal__content no-scrollbar">
                  {
                    landingPageData.frame_animation &&
                    landingPageData.frame_animation[visibleFrame] &&
                    <StandardLoadImage image={landingPageData.frame_animation[visibleFrame]} maxWidth={1200} />
                  }
                </div>
              </div>
            </div>
          </motion.div>
        }
      </AnimatePresence>
      {
        landingPageData.text &&
        <ReactMarkdown className="landing-page__text font--wotfard">{landingPageData.text}</ReactMarkdown>
      }
    </div>
  )

}

export default LandingPage;