import { useState, useEffect } from 'react';
import { getImage } from '../utils/cockpit';

const StandardLoadImage = (props) => {

  const { image, maxWidth, maxHeight, isBackgroundImage, ref } = props;
  const [imageSrc, setImageSrc] = useState('');

  useEffect(() => {
    if (image.path) {
      const path = image.path;
      if (path.indexOf('/storage') === 0) {
        getImage(path, maxWidth ? maxWidth : 2500, maxWidth ? maxWidth : 2500, 60, setImageSrc);
      } else {
        setImageSrc(path);
      }
    }
  }, [image.path, maxWidth, maxHeight]);

  if (image.path && image.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--standard-load`}
          style={{
            backgroundImage: `url(${imageSrc})`
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--standard-load`}
          src={imageSrc}
          alt={image.title}
          ref={ref}
        />
      )
    }
  } else {
    return null
  }
}

export default StandardLoadImage;