import { useState, useEffect } from 'react';
import { getImage, cockpitUrl } from '../utils/cockpit';

const LazyloadImage = (props) => {

  const { image, maxWidth, maxHeight, isBackgroundImage, assets } = props;
  const [imageSrc, setImageSrc] = useState('');
  const [imageIsLoaded, setImageIsLoaded] = useState(false);

  useEffect(() => {
    let img;
    const onImageLoad = () => {
      if (img) {
        setImageIsLoaded(true);
      }
    }

    if (image) {
      if (image.path) {
        const path = image.path;
        if (path.indexOf('/storage') === 0) {

          let quality = 90;

          const setQualityBasedOnSize = (kilobytes) => {
            if (kilobytes <= 500) {
              quality = 100;
            } else if (kilobytes <= 1000) {
              quality = 60;
            } else {
              quality = 30;
            }
          }

          if (assets) {
            if (assets[0]) {
              for (let asset of assets) {
                if ('/storage/uploads' + asset.path === image.path) {
                  setQualityBasedOnSize(asset.size / 1000);
                }
              }
            } else {
              quality = 90;
            }
          } else {
            fetch(`${cockpitUrl}/api/cockpit/assets?token=${process.env.REACT_APP_API_KEY}`, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                // filter: {...}
              })
            })
              .then(allAssets => allAssets.json())
              .then((allAssets) => {
                for (let asset of allAssets.assets) {
                  if ('/storage/uploads' + asset.path === image.path) {
                    setQualityBasedOnSize(asset.size / 1000);
                  }
                }
              });
          }

          getImage(path,
            maxWidth ? maxWidth : 2500,
            maxHeight ? maxHeight : 2500,
            quality,
            setImageSrc,
            (imageUrl) => {
              const url = imageUrl;
              setImageSrc(url);
              img = document.createElement('img');
              img.addEventListener('load', onImageLoad);
              img.src = url;
            }
          )
        } else {
          setImageSrc(path);
          img = document.createElement('img');
          img.addEventListener('load', onImageLoad);
          img.src = path;
        }
      }
    }

    return () => {
      if (img) {
        img.removeEventListener('load', onImageLoad);
      }
    }
  }, [image, maxWidth, maxHeight, assets]);

  if (image?.path && image?.path !== '') {
    if (isBackgroundImage === true) {
      return (
        <div
          className={`background-image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          style={{
            backgroundImage: `url(${imageSrc})`
          }}
        />
      )
    } else {
      return (
        <img
          className={`image--lazyload${imageIsLoaded === true ? ' loaded' : ''}`}
          src={imageSrc}
          alt={image.title}
        />
      )
    }
  } else {
    return null
  }
}

export default LazyloadImage;