import { useRef, useState, useEffect } from 'react';
import WorkThumbnail from './WorkThumbnail';
import { Link } from 'react-router-dom';
import map from '../../utils/misc/map';

const WorkItem = (props) => {

  const itemRef = useRef();
  const thumbnailListItem = useRef();
  const [thumbnailScrollAmount, setThumbnailScrollAmount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const { item, windowDimensions, visible, index, scrollAmount, workSection } = props;
  const { windowHeight } = windowDimensions;
  const { title, title_slug, client_slug, tagline, thumbnail } = item;

  const handleMouseEnterLink = () => {
    setIsHovered(true);
  }

  const handleMouseLeaveLink = () => {
    setIsHovered(false);
  }

  useEffect(() => {
    if (itemRef.current && workSection.current) {
      // when the item scrolls in from the bottom of the screen
      const startPoint = itemRef.current.offsetTop + workSection.current.offsetTop - windowHeight;
      // when the item is scrolled off the top of the screen
      const endPoint = itemRef.current.offsetTop + workSection.current.offsetTop + itemRef.current.offsetHeight;
      const percentageScrolled = Math.max(0, Math.min(100, map(scrollAmount, startPoint, endPoint, 0, 100)));

      setThumbnailScrollAmount(percentageScrolled);

    }
  }, [scrollAmount, windowHeight, workSection, index]);

  return (
    <div
    className={`work__items__item${visible === true ? ' visible' : ''} work-page`}
      ref={itemRef}
    >
      <div
        className={`work__item__inner${thumbnailScrollAmount > 15 ? ' scrolled' : ''}`}
      >
        <div className="work__item__inner__background">
          <div
            className={`work__items__item__text__wrapper side--${index % 2 === 1 ? 'right' : 'left'}`}
          >
            <Link
              to={`/work/${client_slug ? `${client_slug}/${title_slug}` : title_slug}`}
            >
              <div className="work__items__item__heading__wrapper">
                <div className="work__items__item__heading__inner">
                  <h3
                    className="work__items__item__heading font--bluu"
                    onMouseEnter={handleMouseEnterLink}
                    onMouseLeave={handleMouseLeaveLink}
                    onBlur={handleMouseLeaveLink}
                  >{title}</h3>
                </div>
              </div>
              <div className="work__items__item__tagline__wrapper">
                <div className="work__items__item__tagline__inner">
                  <p
                    className="work__items__item__tagline font--karrik"
                    onMouseEnter={handleMouseEnterLink}
                    onMouseLeave={handleMouseLeaveLink}
                    onBlur={handleMouseLeaveLink}
                  >{tagline}</p>
                </div>
              </div>
            </Link>
          </div>
          <div
            className={`work__items__item__thumbnail__outer side--${index % 2 === 0 ? 'right' : 'left'}`}
            ref={thumbnailListItem}
            onMouseEnter={handleMouseEnterLink}
            onMouseLeave={handleMouseLeaveLink}
            onBlur={handleMouseLeaveLink}
          >
            <WorkThumbnail
              {...props}
              path={client_slug ? `/work/${client_slug}/${title_slug}` : `/work/${title_slug}`}
              item={item}
              thumbnail={thumbnail}
              windowDimensions={windowDimensions}
              thumbnailScrollAmount={thumbnailScrollAmount}
              isHovered={isHovered}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkItem;