import { useState } from "react";
import StandardLoadImage from "../components/StandardLoadImage";

const ContactLink = (props) => {

  const { item, windowDimensions } = props;
  const { windowWidth } = windowDimensions;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <li
      className="contact__link"
      onMouseEnter={() => { setIsHovered(true); }}
      onTouchStart={() => { setIsHovered(true); }}
      onMouseLeave={() => { setIsHovered(false); }}
      onTouchEnd={() => { setIsHovered(false); }}
      onBlur={() => { setIsHovered(false); }}
    >
      {
        isHovered === true && item.image && windowWidth >= 768 &&
        <div className="contact__link__img__wrapper">
          <StandardLoadImage image={item.image} />
        </div>
      }
      <a href={item.email ? `mailto:${item.email}` : item.url} target="_blank" rel="noreferrer">{item.text}</a>
    </li>
  )
}

const Contact = (props) => {

  const { contactData, windowDimensions } = props;
  const { windowWidth } = windowDimensions;

  return (
    <div
      key='contact'
      style={{
        height: window.innerHeight + 'px'
      }}
      className={`section section--contact${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
    >
      <div
        className="section__inner section--contact__inner"
        style={{
          height: window.innerHeight + 'px'
        }}
      >
        <div className="section--contact__content">
          {
            windowWidth < 768 &&
            <h2 className="section__title section__title--scrolling section--work__title font--voyage" style={{ textTransform: 'uppercase' }}>Contact</h2>
          }
          {
            contactData.heading &&
            <h3 className="contact__subheading font--wotfard">
              {contactData.heading}
            </h3>
          }
          {
            contactData.links &&
            contactData.links[0] &&
            <ul className="contact__links">
              {
                contactData.links.map(
                  (item, i) => (
                    <ContactLink item={item.value} key={i} windowDimensions={windowDimensions} />
                  )
                )
              }
            </ul>
          }
        </div>
      </div>
    </div>
  )

}

export default Contact;