import { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { cockpitUrl } from '../../utils/cockpit';
import LazyloadImage from '../LazyloadImage';
import ProjectGalleryImage from './ProjectGalleryImage';
import VideoPlayer from '../VideoPlayer';
import ThreeSixtyVideo from './ThreeSixtyVideo';

const ProjectGalleryRow = (props) => {

  const { item, windowDimensions, projectScrollAmount, index } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const [galleryItems, setGalleryItems] = useState([]);

  const galleryRow = useRef();

  useEffect(() => {
    if (item) {
      const length = item.length;
      let remainingWidth = 400;
      const newArray = [];
      for (let image of item) {
        const newImage = { ...image };
        newImage.width = Math.round(Math.min(((100 / length)), remainingWidth));
        remainingWidth -= newImage.width;
        newArray.push(newImage);
      }
      setGalleryItems(newArray);
    }
  }, [item]);

  return (
    <div
      ref={galleryRow}
      className="project__row--gallery"
      style={{
        transform: projectScrollAmount < galleryRow.current?.offsetTop - (windowHeight / 2)
          ?
          `translateY(12%)` : 'translateY(0)'
      }}
    >
      <div
        className="project__row--gallery__inner"
        style={{
          width: windowWidth + windowHeight + 'px',
          transform: index % 2 === 0 ?
            `translateX(-${(projectScrollAmount + (windowHeight) - galleryRow.current?.offsetTop) / 2}px)`
            :
            `translateX(${(projectScrollAmount + (windowHeight) - galleryRow.current?.offsetTop) / 2 - windowHeight}px)`
        }}
      >
      {
        galleryItems &&
        galleryItems[0] &&
        galleryItems.map(
          (image, i) => (
            <ProjectGalleryImage image={image} key={i} />
          )
        )
      }
      {
        galleryItems &&
        galleryItems[0] &&
        galleryItems.map(
          (image, i) => (
            <ProjectGalleryImage image={image} key={i} />
          )
        )
      }
      </div>
    </div>
  )
}

const ProjectRowColumn = (props) => {

  const { field, value, isFullBleed, projectScrollAmount, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const { column_width_desktop, column_width_mobile, column_offset_desktop, column_offset_mobile, column_offset_top_desktop, column_offset_top_mobile, image, video, video_url, text, fontSize } = value;
  const col = useRef();

  return (
    <div
      className={`project__row__col col ${isFullBleed === false ? `col-xxs-${column_width_mobile ? column_width_mobile : 12} col-sm-${column_width_desktop ? column_width_desktop : 6}
      offset-xxs-${column_offset_mobile ? column_offset_mobile : 0} offset-sm-${column_offset_desktop ? column_offset_desktop : 0}`
        : `col--full-bleed`}`}
      ref={col}
      style={{
        transform: projectScrollAmount < (col.current?.offsetTop) - (windowHeight * 0.75)
          ?
          `translateY(12%)` : 'translateY(0)',
        paddingTop: windowWidth < 768 && column_offset_top_mobile ? column_offset_top_mobile + '%' : windowWidth >= 768 && column_offset_top_desktop ? column_offset_top_desktop + '%' : null
      }}
    >
    {
      field.name === 'image_column' &&
      <LazyloadImage {...props} image={image ? image : ''} />
      }
    {
      field.name === 'image_column' && image && image.meta?.title &&
      <p className="project__row__col--img__caption">{image.meta.title}</p>
    }
    {
      field.name === 'text_column' &&
      <ReactMarkdown className={`project__row__col__text${fontSize ? ' project__row__col__text--' + fontSize : ''} font--wotfard`}>{text}</ReactMarkdown>
    }
    {
      field.name === 'video_column' &&
          value.threesixty !== true ?
          <div>
            <VideoPlayer url={cockpitUrl + '/' + video} {...value} isFullBleed={isFullBleed} />
            {
              value.caption &&
              <p
                className={`video__caption${isFullBleed === true ? ' full-bleed' : ''}`}
              >{value.caption}</p>
            }
          </div>
        :
        video &&
        <ThreeSixtyVideo url={cockpitUrl + '/' + video} {...value} isFullBleed={isFullBleed} />
    }
    {
      field.name === 'video_embed_column' &&
      <div>
        <VideoPlayer url={video_url} {...value} isFullBleed={isFullBleed} />
      {
        value.caption &&
        <p
          className={`video__caption${isFullBleed === true ? ' full-bleed' : ''}`}
        >{value.caption}</p>
      }
      </div>
    }
    </div>
  )
}

const ProjectRow = (props) => {

  const { item, projectScrollAmount, windowDimensions, fullBleedRow } = props;
  const row = useRef();

  return (
    <div
      ref={row}
      className={`columns__wrapper project__row${fullBleedRow === true ? ' project__row--full-bleed' : ''}`}
    >
      {
        item &&
        item[0] &&
        item.map(
          (column, i) => (
            <ProjectRowColumn
              field={column.field}
              isFullBleed={fullBleedRow === true ? true : false}
              value={column.value}
              key={i}
              projectScrollAmount={projectScrollAmount}
              windowDimensions={windowDimensions}
              row={row}
            />
          )
        )
      }
    </div>
  )
}

const ProjectContent = (props) => {

  const { item, windowDimensions, projectScrollAmount, setPageName, thumbnail } = props;

  useEffect(() => {
    setPageName(item.title);
  }, [setPageName, item.title]);

  return (
    <div className="project__content">
      <div className="project__content__inner">
        <div className="project__content__thumbnail__wrapper">
          <div className="project__content__thumbnail__inner" ref={thumbnail}>
            <LazyloadImage image={item.thumbnail} maxWidth={2500} maxHeight={2500} isBackgroundImage={false} />
          </div>
        </div>
        <div className="project__meta">
          {
            item.client &&
            <div className="project__meta__pairing">
              <h4 className="project__meta__heading">Client</h4>
              <p className="project__meta__item font--wotfard">{item.client}</p>
            </div>
          }
          {
            item.role &&
            <div className="project__meta__pairing">
              <h4 className="project__meta__heading">Role</h4>
              <p className="project__meta__item font--wotfard">{item.role}</p>
            </div>
          }
          {
            item.agency &&
            <div className="project__meta__pairing">
              <h4 className="project__meta__heading">Agency</h4>
              <p className="project__meta__item font--wotfard">{item.agency}</p>
            </div>
          }
          {
            item.year &&
            <div className="project__meta__pairing">
              <h4 className="project__meta__heading">Year</h4>
              <p className="project__meta__item font--wotfard">{item.year}</p>
            </div>
          }
          {
            item.additional_info &&
            item.additional_info.map(
              (infoItem, i) => (
                <div className="project__meta__pairing" key={i}>
                  <h4 className="project__meta__heading">{infoItem.value.name}</h4>
                  <p className="project__meta__item font--wotfard">{infoItem.value.content}</p>
                </div>
              )
            )
          }
        </div>
        {
          item.projectContent &&
          item.projectContent.map(
            (row, i) => (
              row.field.name === 'gallery' ?
                row.value.gallery &&
                <ProjectGalleryRow key={i} index={i} item={row.value.gallery} windowDimensions={windowDimensions} projectScrollAmount={projectScrollAmount} />
                :
                  row.field.name === 'row' ?
                    row.value.row &&
                    <ProjectRow key={i} item={row.value.row} projectScrollAmount={projectScrollAmount} windowDimensions={windowDimensions} />
                    :
                    row.field.name === 'full_bleed_row' ?
                    row.value.full_bleed_row &&
                    <ProjectRow key={i} item={row.value.full_bleed_row} projectScrollAmount={projectScrollAmount} windowDimensions={windowDimensions} fullBleedRow={true} />
                    :
                    row.value.vertical_columns &&
                    ''
                    // <ProjectRow key={i} item={row.value.vertical_columns} projectScrollAmount={projectScrollAmount} windowDimensions={windowDimensions} />
            )
          )
      }
    </div>
  </div>
  )
}

export default ProjectContent;