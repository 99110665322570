import { useRef, useState } from 'react';
import * as _ from 'underscore';
import { motion } from 'framer-motion';
import ProjectContent from '../components/project/ProjectContent';

const Project = (props) => {

  const { windowDimensions, item, menuIsOpen, menuItemIsActive, navigate } = props;
  const { windowHeight } = windowDimensions;
  const [projectScrollAmount, setProjectScrollAmount] = useState(0);
  const [isHeadingFixed, setIsHeadingFixed] = useState(true);

  const thumbnail = useRef();
  const projectHeading = useRef();

  const handleIndividualProjectScroll = (e) => {
    if (e.target.scrollTop > e.target.scrollHeight - windowHeight * 1.1) {
      navigate('/work');
    }
    setProjectScrollAmount(e.target.scrollTop);
    if (thumbnail.current) {
      if (e.target.scrollTop < thumbnail.current.offsetHeight + thumbnail.current.offsetTop - projectHeading.current.offsetHeight) {
        if (isHeadingFixed === false) {
          setIsHeadingFixed(true);
        }
      } else {
        if (isHeadingFixed === true) {
          setIsHeadingFixed(false);
        }
      }
    }
  }

  const handleIndividualProjectScrollThrottle = _.throttle(handleIndividualProjectScroll, 20);

  return (
    <motion.div
      initial={{ y: windowHeight }}
      animate={{ y: 0 }}
      exit={{ y: 0 - windowHeight }}
      transition={{
        duration: 0.9,
        type: 'ease'
      }}
      className={`project--pdp${menuIsOpen === true ? ' project--pdp--menu--open' : ''}${menuItemIsActive === true ? ' project--pdp--menu-item--active' : ''}`}
      style={{
        height: windowHeight + 'px'
      }}
    >
      <div
        className="project--pdp__inner"
        onScroll={handleIndividualProjectScrollThrottle}
      >
        <motion.header
          animate={{ y: isHeadingFixed === true ? 0 : '-200%' }}
          className="project__heading"
          transition={{
            ease: 'linear',
            duration: 0.3
          }}
          ref={projectHeading}
        >
          <h2 className="project__title font--bluu">{item.title}</h2>
          <p className="project__tagline">{item.tagline}</p>
        </motion.header>
        <ProjectContent
          {...props}
          projectScrollAmount={projectScrollAmount}
          thumbnail={thumbnail}
        />
      </div>
    </motion.div>
  )
}

export default Project;