import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import LazyloadImage from "../LazyloadImage";

const WorkThumbnail = (props) => {

  const { thumbnail, windowDimensions, path, thumbnailScrollAmount, location, isHovered } = props;
  const { pathname } = location;
  const { windowWidth } = windowDimensions;

  return (
    <motion.div
      className={`work__items__item__thumbnail__wrapper${isHovered === true && path !== pathname ? ' hovered' : ''}`}
    >
      <Link
        className="work__items__item__thumbnail__wrapper__link"
        to={path}
      >
        <motion.div
          className="work__items__item__thumbnail"
          animate={{
            scale: pathname === path ? 1 : 0.9 + thumbnailScrollAmount / 100 * 0.2,
            y: pathname === path ? 0 : 10 - thumbnailScrollAmount / 100 * 10
          }}
          transition={{
            duration: pathname === path ? .1 : 0
          }}
        >
          <LazyloadImage {...props} image={thumbnail} maxWidth={windowWidth < 768 ? 1500 : 2500} maxHeight={windowWidth < 768 ? 1500 : 2500} isBackgroundImage={true} />
        </motion.div>
      </Link>
    </motion.div>
  )
}

export default WorkThumbnail;