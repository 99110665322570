import { useState, useEffect } from 'react';
import * as _ from 'underscore';
import ProjectContent from '../components/project/ProjectContent';

const ProjectPreview = (props) => {

  const { windowDimensions, workData } = props;
  const [projectScrollAmount, setProjectScrollAmount] = useState(0);
  const [item, setItem] = useState(workData.entries ? workData.entries[0] ? workData.entries[0] : {} : {});
  const { windowHeight } = windowDimensions;

  useEffect(() => {
    if (!item._id && workData.entries) {
      if (workData.entries[0]) {
        setItem(workData.entries[0]);
      }
    }
  }, [item, workData]);

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data?.entry) {
          setItem(event.data.entry);
        }
      },
      false);
  }, []);

  const handleIndividualProjectScroll = (e) => {
    setProjectScrollAmount(e.target.scrollTop);
  }

  const handleIndividualProjectScrollThrottle = _.throttle(handleIndividualProjectScroll, 20);

  return (
    <div
      className="project--pdp"
      style={{
        height: windowHeight + 'px'
      }}
    >
      <header className="project__heading">
        <h2 className="project__title font--bluu">{item.title}</h2>
        <p className="project__tagline">{item.tagline}</p>
      </header>
      <div
        className="project--pdp__inner"
        onScroll={handleIndividualProjectScrollThrottle}
      >
        {
          <ProjectContent
            {...props}
            item={item}
            projectScrollAmount={projectScrollAmount}
          />
        }
      </div>
    </div>
  );
}

export default ProjectPreview;