import React, { useRef, useState, useEffect } from 'react';
import '../css/index.css';
import * as _ from 'underscore';
import Content from './Content';
import { fetchCockpitData, cockpitUrl } from './utils/cockpit.js';
import { useLocation, useNavigate } from 'react-router-dom';

const App = (props) => {

  const [assets, setAssets] = useState([]);
  const [activeTitleText, setActiveTitleText] = useState('');

  const onFirstTouch = () => {
    window.USER_IS_TOUCHING = true;
  }

  const [scrollAmount, setScrollAmount] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuItemIsActive, setMenuItemIsActive] = useState(false);
  const [pageName, setPageName] = useState('');

  const [workData, setWorkData] = useState({});
  const [aboutData, setAboutData] = useState([]);
  const [playData, setPlayData] = useState([]);
  const [contactData, setContactData] = useState({});
  const [landingPageData, setLandingPageData] = useState({});
  const [siteOptionsData, setSiteOptionsData] = useState({});
  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });
  const [activeAboutSection, setActiveAboutSection] = useState(-1);
  const { windowHeight } = windowDimensions;
  const [isInitialised, setIsInitialised] = useState(false);

  const homeSection = useRef();
  const workSection = useRef();
  const aboutSection = useRef();
  const playSection = useRef();
  const contactSection = useRef();
  const wrapper = useRef();

  const location = useLocation();
  const navigate = useNavigate();

  const scrollTimeout = useRef();

  useEffect(() => {
    const { pathname } = location;
    if (isInitialised === false) {
      if (wrapper.current) {
        let element;
        if (pathname === '/work' && workSection.current) {
          element = workSection.current;
        } else if (pathname === '/contact' && contactSection.current) {
          element = contactSection.current;
        } else if (pathname === '/about' && aboutSection.current) {
          element = aboutSection.current;
        } else if (pathname === '/play' && playSection.current) {
          setPageName('Play');
          element = playSection.current;
        } else {
          setIsInitialised(true);
        }

        if (element) {
          const scrollToPoint = element.offsetTop;
          wrapper.current.scrollTo({
            top: scrollToPoint,
            left: 0
          });
          setIsInitialised(true);
        }
      }
    }
  }, [isInitialised, location]);


  const handleScroll = (e) => {
    const scroll = e.target.scrollTop;
    const { pathname } = location;
    setScrollAmount(scroll);
    if (scroll >= playSection.current.offsetTop) {
      if (pathname !== '/play') {
        navigate('/play');
      }
    } else if (scroll >= contactSection.current.offsetTop) {
      if (pathname !== '/contact') {
        navigate('/contact');
      }
    } else if (scroll >= aboutSection.current.offsetTop) {
      if (pathname.indexOf('/about') === -1) {
        navigate('/about');
      }
    } else if (scroll >= workSection.current.offsetTop - windowHeight / 2) {
      if (pathname.indexOf('/work') === -1) {
        navigate('/work');
      }
    } else if (scroll < workSection.current.offsetTop - windowHeight / 2) {
      if (pathname !== '/') {
        navigate('/');
      }
    }

    clearTimeout(scrollTimeout.current);

    if (workSection.current) {
      workSection.current.style.pointerEvents = 'none';
      scrollTimeout.current = setTimeout(() => {
        workSection.current.style.pointerEvents = null;
      }, 200);
    }
  }

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/') {
      setPageName('');
      setActiveTitleText('');
    } else if (pathname.indexOf('/work') === 0) {
      setActiveTitleText('');
      if (pathname === '/work') {
        setPageName('Work');
      }
    } else if (pathname.indexOf('/about') === 0) {

    } else if (pathname === '/contact') {
      setPageName('Contact');
      setActiveTitleText('Contact');
    } else if (pathname === '/play') {
      setPageName(`Play`);
      setActiveTitleText(`2k${new Date().getFullYear().toString().substring(2)}`);
    }
  }, [location, aboutData]);

  const handleScrollThrottled = _.throttle(handleScroll, 20);

  const getSiteData = () => {
    fetchCockpitData('work', setWorkData, true);
    fetchCockpitData('about', setAboutData, false);
    fetchCockpitData('play', setPlayData, true);
    fetchCockpitData('contact', setContactData, false);
    fetchCockpitData('landing_page', setLandingPageData, false);
    fetchCockpitData('site_options', setSiteOptionsData, false);
  }

  useEffect(() => {
    getSiteData();
  }, []);

  useEffect(() => {

  }, [pageName]);

  useEffect(() => {
    const { pathname } = location;

    const handleWindowResize = () => {
      const fullHeightElements = document.querySelectorAll('.full-height');
      if (fullHeightElements[0]) {
        for (let fullHeightElement of fullHeightElements) {
          fullHeightElement.style.height = window.innerHeight + 'px';
          setWindowDimensions({
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight
          });
        }
      }
    }

    const handleClick = (e) => {
      let linkElement;
      if (e.target.href) {
        linkElement = e.target;
      } else if (e.target.closest('a')) {
        if (e.target.closest('a').href) {
          linkElement = e.target.closest('a');
        }
      }

      if (linkElement) {
        const url = linkElement.href;
        if (url.indexOf(window.location) !== 0 && url.indexOf('/') !== 0) {
          e.preventDefault();
          window.open(url, '_blank');
        }
      }
    }

    if (pathname) {
      handleWindowResize();
    }
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('click', handleClick);
    }
  }, [location]);

  useEffect(() => {
    fetch(`${cockpitUrl}/api/cockpit/assets?token=${process.env.REACT_APP_API_KEY}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
      })
    })
    .then(assets => assets.json())
      .then((assets) => {
        setAssets(assets);
      });
  }, []);

  return (
    <Content
      {...props}
      onFirstTouch={onFirstTouch}
      assets={assets}
      activeTitleText={activeTitleText}
      setActiveTitleText={setActiveTitleText}
      windowDimensions={windowDimensions}
      scrollAmount={scrollAmount}
      setScrollAmount={setScrollAmount}
      menuIsOpen={menuIsOpen}
      setMenuIsOpen={setMenuIsOpen}
      menuItemIsActive={menuItemIsActive}
      setMenuItemIsActive={setMenuItemIsActive}
      workData={workData}
      setWorkData={setWorkData}
      aboutData={aboutData}
      pageName={pageName}
      setPageName={setPageName}
      setAboutData={setAboutData}
      playData={playData}
      setPlayData={setPlayData}
      contactData={contactData}
      setContactData={setContactData}
      landingPageData={landingPageData}
      siteOptionsData={siteOptionsData}
      homeSection={homeSection}
      workSection={workSection}
      aboutSection={aboutSection}
      playSection={playSection}
      contactSection={contactSection}
      wrapper={wrapper}
      scrollTimeout={scrollTimeout}
      handleScrollThrottled={handleScrollThrottled}
      activeAboutSection={activeAboutSection}
      setActiveAboutSection={setActiveAboutSection}
      location={location}
      navigate={navigate}
    />
  )
};

export default App;
