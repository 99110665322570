import AboutInnerSection from '../components/about/AboutInnerSection';

const About = (props) => {

  const { aboutData } = props;

  return (
    <div
      key='about'
      className={`section section--about${props.menuIsOpen === true ? ' section--menu-is-open' : '' }`}
    >
      <div
        className="section__inner section--about__inner container-fluid"
      >
        <ul className="about__items columns__wrapper">
          {
            aboutData.about ?
              aboutData.about.map(
                (item, i) => <li className="about__item" key={i}>
                  <AboutInnerSection {...item} {...props} index={i} />
                </li>
              )
            :
            ''
          }
        </ul>
      </div>
    </div>
  )

}

export default About;