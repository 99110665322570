import { motion } from "framer-motion";
import VideoPlayer from "../VideoPlayer";
import LazyloadImage from '../LazyloadImage';
import ReactMarkdown from 'react-markdown';
import { useState, useEffect } from "react";

const PlayWindowVideo = (props) => {

  const { video, item, frontItemId } = props;
  const { _id, shown } = item;
  const [muted, setMuted] = useState(_id === frontItemId ? true : false);

  useEffect(() => {
    if (_id === frontItemId && shown === true) {
      setMuted(false);
    } else {
      setMuted(true);
    }
  }, [_id, frontItemId, shown]);

  return (
    <VideoPlayer url={video} isLooping={true} noControls={true} playing={true} muted={muted} />
  )
}

const PlayWindowImage = (props) => {

  const { image } = props;

  return (
    <div className="play__window--image__content">
      <LazyloadImage {...props} image={image} />
    </div>
  )
}

const PlayWindowText = (props) => {

  const { text } = props;

  return (
    <div className="play__window--text__content">
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  )
}

const PlayWindow = (props) => {

  const { container, item, windowContents, windowDimensions, modal, handleMouseDown, handleCloseWindow, location, isPreview } = props;
  const { pathname } = location;
  const { windowWidth, windowHeight } = windowDimensions;
  const { video, image, text } = windowContents;

  if (item.shown === true) {
    return (
      <motion.div
        ref={modal}
        className="modal modal--item play__modal"
        drag={isPreview === true ? false : true}
        dragConstraints={container}
        dragElastic={false}
        dragMomentum={false}
        style={{
          opacity: item.shown === true ? '1' : '0',
          pointerEvents: item.shown === true ? 'auto' : 'none',
          zIndex: item.index + 1,
          left: isPreview === true ? item.x / 100 * windowWidth + 'px' : null,
          top: isPreview === true ? item.y / 100 * windowHeight + 'px' : null
        }}
        onMouseDown={() => { handleMouseDown(item); }}
        onTouchStart={() => { handleMouseDown(item); }}
        initial={{
          x: isPreview === true ? 0 : item.x / 100 * windowWidth,
          y: isPreview === true ? 0 : item.y / 100 * windowHeight
        }}
        animate={{
          x: isPreview === true ? 0 : item.x / 100 * windowWidth,
          y: isPreview === true ? 0 : item.y / 100 * windowHeight
        }}
        transition={{
          duration: 0.4,
          delay: pathname !== '/play' ? 0 : 0.4
        }}
      >
        <div className="modal__inner">
          <div className="modal__header modal--folder__header">
            <div className="modal__header__inner">
              <div className="modal__header__buttons">
                <button
                  className="folder__header__close modal__header__button modal__header__button--close"
                  onClick={() => handleCloseWindow(item)}
                ></button>
                <button
                  className="folder__header__minimise modal__header__button modal__header__button--minimise"
                  onClick={() => handleCloseWindow(item)}
                ></button>
                <div className="folder__header__fullscreen modal__header__button modal__header__button--fullscreen" />
              </div>
              <h2 className="modal__header__title">{item.title}</h2>
            </div>
          </div>
          <div className="modal__content modal__content--individual-item">
            {
              windowContents &&
              <div className="modal__content__inner">
                {
                  video &&
                  <PlayWindowVideo video={video} {...props} />
                }
                {
                  image &&
                  <PlayWindowImage image={image} />
                }
                {
                  text &&
                  <PlayWindowText text={text} />
                }
              </div>
            }
          </div>
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
}

export default PlayWindow;