import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { cockpitUrl } from '../utils/cockpit';

const Header = (props) => {

  const { siteOptionsData, wrapper } = props;

  return (
    <motion.header
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="header"
    >
      <Link
        className="header__link"
        to="/"
        onClick={() => {
          if (wrapper.current) {
            wrapper.current.scrollTo({
              top: 0,
              left: 0,
              behavior: 'smooth'
            });
          }
        }}
      >
        {
          siteOptionsData.logo ?
            <img
              src={siteOptionsData.logo.path.indexOf('/storage') === 0 ? cockpitUrl + siteOptionsData.logo.path : siteOptionsData.logo.path}
              alt="TeYosh logo"
            />
          :
          <h1 className="header">TEYOSH</h1>
        }
      </Link>
    </motion.header>
  )
}

export default Header;