import { Link } from 'react-router-dom';

const Menu = (props) => {

  const { location, windowDimensions, setMenuItemIsActive, menuIsOpen, setMenuIsOpen, workSection, aboutSection, playSection, contactSection, wrapper } = props;
  const { pathname } = location;
  const { windowWidth } = windowDimensions;

  const handleClickItem = (e, section) => {
    if (windowWidth < 768) {
      if (menuIsOpen === false) {
        e.preventDefault();
      }
      setMenuIsOpen(!menuIsOpen);
    } else {
      setMenuItemIsActive(false);
    }
    if (section.current && wrapper.current) {
      wrapper.current.scrollTo({
        left: 0,
        top: section.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  return (
    <nav className={`menu${(pathname === '/' && windowWidth >= 768) || (windowWidth < 768 && menuIsOpen === true) ? ' expanded' : ''}`}>
      <ul className="menu__list">
        <li className="menu__item">
          <Link
            onClick={(e) => handleClickItem(e, workSection)}
            onMouseEnter={() => { setMenuItemIsActive(true); }}
            onMouseLeave={() => { setMenuItemIsActive(false); }}
            onBlur={() => { setMenuItemIsActive(false); }}
            to="/work" className={`menu__item__link${(pathname === '/' && windowWidth >= 768) || (windowWidth < 768 && menuIsOpen) ? ' expanded' : ''} font--pilowlava`}
          >
            Work
          </Link>
        </li>
        <li className="menu__item">
          <Link
            onClick={(e) => handleClickItem(e, aboutSection)}
            onMouseEnter={() => { setMenuItemIsActive(true); }}
            onMouseLeave={() => { setMenuItemIsActive(false); }}
            onBlur={() => { setMenuItemIsActive(false); }}
            to="/about" className={`menu__item__link${(pathname === '/' && windowWidth >= 768) || (windowWidth < 768 && menuIsOpen) ? ' expanded' : ''} font--lack`}
          >
            About
          </Link>
        </li>
        <li className="menu__item">
          <Link
            onClick={(e) => handleClickItem(e, contactSection)}
            onMouseEnter={() => { setMenuItemIsActive(true); }}
            onMouseLeave={() => { setMenuItemIsActive(false); }}
            onBlur={() => { setMenuItemIsActive(false); }}
            to="/contact" className={`menu__item__link${(pathname === '/' && windowWidth >= 768) || (windowWidth < 768 && menuIsOpen) ? ' expanded' : ''} font--voyage`}
          >
            Contact
          </Link>
        </li>
        <li className="menu__item">
          <Link
            onClick={(e) => handleClickItem(e, playSection)}
            onMouseEnter={() => { setMenuItemIsActive(true); }}
            onMouseLeave={() => { setMenuItemIsActive(false); }}
            onBlur={() => { setMenuItemIsActive(false); }}
            to={`/play`} className={`menu__item__link${(pathname === '/' && windowWidth >= 768) || (windowWidth < 768 && menuIsOpen) ? ' expanded' : ''} font--chaney`}
          >
            2k{new Date().getFullYear().toString().substring(2)}
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Menu;