import { useRef, useState, useEffect } from 'react';
import LazyloadImage from '../LazyloadImage';

const ConditionalLink = ({ url, isEnabled, children }) => {
  if (url && isEnabled === true) {
    return <a target="_blank" rel="noreferrer" href={url} className="about__item__list__item__link">{children}</a>
  } else {
    return <span>{children}</span>
  }
}

const LinksList = (props) => {

  const { list, windowDimensions } = props;
  const { windowWidth } = windowDimensions;
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div className="about__item__list__wrapper">
      {
        list[activeIndex] &&
        list[activeIndex].value?.image &&
        windowWidth >= 768 &&
        <div className="about__item__list__active-image__wrapper">
          <div className="about__item__list__active-image__inner">
            <LazyloadImage {...props} image={list[activeIndex].value?.image} />
          </div>
        </div>
      }
      <ul className="about__item__list about__item__list--links">
        {
          list[0] &&
          list.map(
            (item, i) => (
              <li
                className="about__item__list__item about__item__list--links__item"
                key={i}
                onMouseEnter={() => setActiveIndex(i)}
                onMouseMove={() => setActiveIndex(i)}
                onMouseLeave={() => setActiveIndex(-1)}
                onBlur={() => setActiveIndex(-1)}
              >
                <ConditionalLink url={item.value.link} isEnabled={windowWidth >= 768}>
                  <span>{item.value.year}</span>
                  <span>{item.value.title}</span>
                  <span>{item.value.location}</span>
                </ConditionalLink>
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

const YearsLocationsList = (props) => {

  const { list, windowDimensions } = props;
  const { windowWidth } = windowDimensions;
  const [activeIndex, setActiveIndex] = useState(-1);

  return (
    <div className="about__item__list__wrapper">
      {
        list[activeIndex] &&
        list[activeIndex].value?.image &&
        windowWidth >= 768 &&
        <div className="about__item__list__active-image__wrapper">
          <LazyloadImage {...props} image={list[activeIndex].value?.image} />
        </div>
      }
      <ul className="about__item__list about__item__list--years-locations">
        {
          list[0] &&
          list.map(
            (item, i) => (
              <li
                className="about__item__list__item about__item__list--years-locations__item font--wotfard--bold"
                key={i}
                onMouseEnter={() => setActiveIndex(i)}
                onMouseMove={() => setActiveIndex(i)}
                onMouseLeave={() => setActiveIndex(-1)}
                onBlur={() => setActiveIndex(-1)}
              >
                <ConditionalLink url={item.value.link} isEnabled={windowWidth >= 768}>
                  <span className="about__item__list--years-locations__item__year">{item.value.year}</span>{' '}
                  <span className="about__item__list--years-locations__item__title">{item.value.title}</span>{' '}
                  <span className="about__item__list--years-locations__item__location">[{item.value.location}]</span>
                  {
                    item.value.link && item.value.link !== '' &&
                    <span className="about__item__list--years-locations__item__link-logo">&#8599;</span>
                  }
                  {
                    i < list.length - 1 &&
                    <span className="about__item__list--years-locations__item__bullet">&#8226;</span>
                  }
                </ConditionalLink>
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

const HeadingsList = (props) => {

  const { list } = props;

  return (
    <div className="about__item__list__wrapper">
      <ul className="about__item__list about__item__list--headings">
        {
          list[0] &&
          list.map(
            (item, i) => (
              <li className="about__item__list__item about__item__list--headings__item" key={i}>
                <h3 className="about__item__list__item__heading about__item__list--headings__item__heading font--pilowlava">{item.value.heading}</h3>
                <p className="about__item__list__item__text about__item__list--headings__item__text font--wotfard">{item.value.text}</p>
              </li>
            )
          )
        }
      </ul>
    </div>
  )
}

const AboutInnerSection = (props) => {

  const { field, value, index, scrollAmount, setActiveTitleText,
    aboutSection, wrapper, location, setPageName, windowDimensions
  } = props;
  const { pathname } = location;
  const { heading, image } = value;
  const { windowWidth } = windowDimensions;

  const section = useRef();

  useEffect(() => {

      if (section.current && aboutSection.current && wrapper.current && scrollAmount) {
        if ((section.current.offsetTop + aboutSection.current.offsetTop) <= wrapper.current.scrollTop) {
          if ((section.current.offsetTop + aboutSection.current.offsetTop + section.current.offsetHeight) >= wrapper.current.scrollTop) {
            setActiveTitleText(heading);
            setPageName('About');
            // headingRef.current.style.transform = `translateY(${wrapper.current.scrollTop - (section.current.offsetTop + aboutSection.current.offsetTop)}px) translateX(-50%)`;
          } else {
            // headingRef.current.style.transform = `translateY(${section.current.offsetHeight - 120}px) translateX(-50%)`;
          }
        }
      }

  }, [pathname, wrapper, aboutSection, section, props.navigate, index, scrollAmount, heading, setActiveTitleText, setPageName]);

  return (
    <div
      className="about__item__wrapper"
      ref={section}
    >
      {
        windowWidth < 768 ?
        <h2 className="about__item__title--mobile">{heading}</h2>
        :
      <div
        className="about__item__separator"
      ></div>
      }
        <div
          className="about__item__inner"
          ref={section}
        >
        {
          field.name === 'headings_list_section' &&
          <div className="about__item--headings_list_section__inner">
            <HeadingsList {...value} />
            <div className="about__item--headings_list_section__img__wrapper">
              {
                image?.path &&
                <LazyloadImage {...props} image={image} />
              }
            </div>
          </div>
        }
        {
          field.name === 'dates_locations_list_section' &&
          <YearsLocationsList {...value} {...props} />
        }
        {
          field.name === 'links_list_section' &&
          <LinksList {...value} {...props} />
        }
      </div>
    </div>
  )
}

export default AboutInnerSection;