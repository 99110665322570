import { useState, useEffect, createRef } from "react";
import WorkItem from '../components/work/WorkItem';

const Work = (props) => {

  const { location, scrollAmount, workSection, workData } = props;
  const { pathname } = location;
  const [refs, setRefs] = useState([]);

  useEffect(() => {
    if (props.workData.entries && refs.length === 0) {
      setRefs(([...Array(props.workData.entries.length).fill(createRef())]));
    }
  }, [props.workData.entries, refs]);

  return (
    <div
      key='work'
      className={`section section--work${props.menuIsOpen === true ? ' section--menu-is-open' : ''}${pathname === `/work` || pathname === `/work/` ? ' is-work' : ''}`}
    >
      <div
        className="section__inner section--work__inner"
      >
        <h2 className="section__title section__title--scrolling section--work__title font--pilowlava">Work</h2>
        <ul className="work__items">
          {
            workData.entries ?
              workData.entries.map(
                (item, i) => (
                  item.published !== false &&
                  <WorkItem
                    item={item}
                    {...props}
                    key={i}
                    index={i}
                    itemRef={refs[i]}
                    scrollAmount={scrollAmount}
                    workSection={workSection}
                    visible={
                      pathname === `/work/${item.title_slug}` || pathname === `/work/${item.client_slug}/${item.title_slug}` || pathname === '/work' || pathname === '/work/' || pathname === '/' ? true : false
                    }
                  />
                )
              )
            :
            ''
          }
        </ul>
      </div>
    </div>
  )

}

export default Work;