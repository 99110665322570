import { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import PlayIcons from '../components/play/PlayIcons';
import PlayWindow from '../components/play/PlayWindow';
import PlayFinderWindow from '../components/play/PlayFinderWindow';

const PlayItem = (props) => {
  const { item, index, itemsOnPage, setItemsOnPage, closeOpenCounter, setCloseOpenCounter } = props;
  const { content } = item;

  const modal = useRef();

  const handleCloseWindow = () => {
    const itemsOnPageArray = itemsOnPage;
    const thisItem = itemsOnPageArray[index];
    thisItem.shown = false;
    setCloseOpenCounter(closeOpenCounter + 1);
    setItemsOnPage(itemsOnPageArray);
  }

  if (content[0]) {
    if (content[0].field.name === 'finder') {
      return <PlayFinderWindow item={item} modal={modal} {...props} windowContents={content[0].value} handleCloseWindow={handleCloseWindow} />
    } else {
      return <PlayWindow item={item} modal={modal} {...props} windowContents={content[0].value} type={content[0].field} handleCloseWindow={handleCloseWindow} />
    }
  } else {
    return null;
  }
}

const Play = (props) => {

  const { playData, windowDimensions, menuIsOpen, menuItemIsActive } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const container = useRef();
  const [frontItemId, setFrontItemId] = useState(-1);
  const [closeOpenCounter, setCloseOpenCounter] = useState(0);

  const [itemsOnPage, setItemsOnPage] = useState([]);

  useEffect(() => {
    const itemsOnPageArray = [];

    let count = 0;
    if (playData.entries && playData.entries[0]) {
      for (let entry of playData.entries) {
        const { start_date, end_date } = entry;

        let startDateInDate = false;
        let endDateInDate = false;
        if (!start_date && !end_date) {
          startDateInDate = true;
          endDateInDate = true;
        } else {
          const todaysDate = new Date();
          const todaysYear = todaysDate.getFullYear();
          const todaysMonth = todaysDate.getMonth();
          const todaysDay = todaysDate.getDay();
          if (start_date) {
            const year = parseInt(start_date.substring(0, 4));
            const month = parseInt(start_date.substring(5, 7));
            const day = parseInt(start_date.substring(8));
            if (todaysYear >= year) {
              if (todaysYear === year) {
                if (todaysMonth >= month) {
                  if (todaysMonth === month) {
                    if (todaysDay >= day) {
                      startDateInDate = true;
                    }
                  } else {
                    startDateInDate = true;
                  }
                }
              } else {
                startDateInDate = true;
              }
            }
          } else {
            startDateInDate = true;
          }
          if (end_date) {
            const year = parseInt(end_date.substring(0, 4));
            const month = parseInt(end_date.substring(5, 7));
            const day = parseInt(end_date.substring(8));
            if (todaysYear <= year) {
              if (todaysYear === year) {
                if (todaysMonth <= month) {
                  if (todaysMonth === month) {
                    if (todaysDay <= day) {
                      startDateInDate = true;
                    }
                  } else {
                    endDateInDate = true;
                  }
                }
              } else {
                endDateInDate = true;
              }
            }
          } else {
            endDateInDate = true;
          }
        }
        if (startDateInDate === true && endDateInDate === true) {
          let newItem = { shown: true, index: count, ...entry };
          itemsOnPageArray.push({ ...newItem });
          count++;
          setFrontItemId(newItem._id);
        }
      }
    }
    setItemsOnPage(itemsOnPageArray);
  }, [playData.entries]);

  const handleMouseDown = (item) => {
    const itemsOnPageArray = [...itemsOnPage];

    for (let i = 0; i < itemsOnPageArray.length; i++) {
      if (itemsOnPageArray[i]._id === item._id) {
        itemsOnPageArray[i].index = itemsOnPageArray.length;
        setFrontItemId(item._id);
      } else {
        itemsOnPageArray[i].index = Math.max(1, itemsOnPageArray[i].index - 1);
      }
    }

    setItemsOnPage(itemsOnPageArray);
  }

  return (
    <motion.div
      className={`view--play${menuIsOpen === true ? ' view--play--menu--open' : ''}${menuItemIsActive === true ? ' view--play--menu-item--active' : ''}`}
      ref={container}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 3 + 'px'
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {
        windowWidth < 768 &&
        <h2 className="section__title section__title--scrolling section--play__title font--chaney" style={{ textTransform: 'uppercase' }}>
          2k{new Date().getFullYear().toString().substring(2)}
        </h2>
      }
      {
        <PlayIcons
          {...props}
          handleMouseDown={handleMouseDown}
          itemsOnPage={itemsOnPage}
          setItemsOnPage={setItemsOnPage}
          closeOpenCounter={closeOpenCounter}
          setCloseOpenCounter={setCloseOpenCounter}
        />
      }
      {
        (props.location.pathname === '/play') &&
        itemsOnPage[0] &&
        itemsOnPage.map(
          (item, i) => (
            (windowWidth >= 768 || item.visibleOnMobile !== false) &&
            <PlayItem
              key={i}
              item={item}
              index={i}
              handleMouseDown={handleMouseDown}
              closeOpenCounter={closeOpenCounter}
              setCloseOpenCounter={setCloseOpenCounter}
              {...props}
              frontItemId={frontItemId}
              container={container}
              itemsOnPage={itemsOnPage}
              setItemsOnPage={setItemsOnPage}
            />
          )
        )
      }
    </motion.div>
  )
}

export default Play;