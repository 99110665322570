import { useRef, useState, useEffect, createRef } from 'react';
import PlayWindow from '../components/play/PlayWindow';
import PlayFinderWindow from '../components/play/PlayFinderWindow';

const PlayItem = (props) => {
  const { item, modal } = props;
  const [isInDate, setIsInDate] = useState(false);
  const { content, start_date, end_date } = item;

  useEffect(() => {
    if (!start_date && !end_date) {
      setIsInDate(true);
    }
  }, [start_date, end_date]);

  if (content) {
    if (content[0] && isInDate === true) {
      if (content[0].field.name === 'finder') {
        return <PlayFinderWindow isPreview={true} item={item} modal={modal} {...props} windowContents={content[0].value} />
      } else {
        return <PlayWindow isPreview={true} item={item} modal={modal} {...props} windowContents={content[0].value} type={content[0].field} />
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}

const PlayPreview = (props) => {

  const { playData, windowDimensions } = props;
  const { windowWidth, windowHeight } = windowDimensions;
  const container = useRef();
  const [refs, setRefs] = useState([]);
  const [closedIDs, setClosedIDs] = useState([]);
  const [activeItem, setActiveItem] = useState({});

  const activeItemRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "message",
      (event) => {
        if (event.data?.entry) {
          setActiveItem(event.data.entry);
        }
      },
      false);
  }, []);

  useEffect(() => {
    if (playData.entries && playData.entries[0]) {
      const refsArray = [];
      for (let i = 0; i < playData.entries.length; i++) {
        const newRef = createRef();
        refsArray.push(newRef);
      }
      setRefs(refsArray);
    }
  }, [playData.entries]);

  const handleMouseDown = (ref) => {
    for (let i = 0; i < refs.length; i++) {
      if (refs[i].current?.style?.zIndex > 0) {
        refs[i].current.style.zIndex = refs[i].current.style.zIndex - 1;
      }
    }
    if (ref.current) {
      ref.current.style.zIndex = ref.current.style.zIndex + 1
    }
  }

  return (
    <div
      className="view--play"
      ref={container}
      style={{
        width: windowWidth + 'px',
        height: windowHeight + 'px'
      }}
    >
      {
        refs[0] &&
        playData.entries &&
        playData.entries[0] &&
        playData.entries.map(
          (item, i) => (
            !closedIDs.includes(i) &&
            item._id !== activeItem._id &&
            <PlayItem
              key={i}
              item={item}
              index={i}
              modal={refs[i]}
              handleMouseDown={handleMouseDown}
              {...props}
              container={container}
              closedIDs={closedIDs}
              setClosedIDs={setClosedIDs}
            />
          )
        )
      }
      {
        <PlayItem
          item={activeItem}
          index={999}
          modal={activeItemRef}
          handleMouseDown={handleMouseDown}
          {...props}
          container={container}
          closedIDs={closedIDs}
          setClosedIDs={setClosedIDs}
        />
      }
    </div>
  )
}

export default PlayPreview;