import { AnimatePresence, motion } from 'framer-motion';
import LazyloadImage from '../LazyloadImage';

const PlayIcon = (props) => {

  const { item, i, handleClickIcon } = props;

  return (
    <motion.button
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className="play__icon"
      aria-label={`open ${item.title} window`}
      onClick={() => handleClickIcon(item, i)}
      style={{
        left: item.iconX + '%',
        top: item.iconY + '%'
      }}
    >
      <LazyloadImage {...props} image={item.icon} alt={item.icon.title} />
    </motion.button>
  )
}

const PlayIcons = (props) => {

  const { itemsOnPage, setItemsOnPage, closeOpenCounter, setCloseOpenCounter, handleMouseDown, windowDimensions } = props;
  const { windowWidth } = windowDimensions;

  const handleClickIcon = (item, i) => {
    const itemsOnPageArray = [...itemsOnPage];
    if (itemsOnPageArray[0]) {
      for (let itemOnPage of itemsOnPageArray) {
        if (item._id === itemOnPage._id) {
          itemOnPage.shown = true;
          setCloseOpenCounter(closeOpenCounter + 1);
          handleMouseDown(item);
        }
      }
    }
    setItemsOnPage(itemsOnPageArray);
  }

  return (
    <div className="play__icons__wrapper">
      <AnimatePresence>
      {
        itemsOnPage &&
        itemsOnPage[0] &&
        itemsOnPage.map(
          (item, i) => (
            item.icon?.path && item.iconIsShown === true &&
            props.location.pathname === '/play' &&
            (windowWidth >= 768 || item.visibleOnMobile === true) &&
            <PlayIcon item={item} key={i} i={i} handleClickIcon={handleClickIcon} />
          )
        )
        }
      </AnimatePresence>
    </div>
  )
}

export { PlayIcon };
export default PlayIcons;