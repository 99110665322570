import { useEffect, useState, useRef, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import * as THREE from 'three';
import rotate3dicon from '../../../assets/3Drotate.png';
import { OrbitControls } from '@react-three/drei';

const ThreeSixtyVideoScene = (props) => {
  const { video } = props;
  const sphere = useRef();

  if (video) {
    return (
      <group>
        <mesh ref={sphere}>
          <sphereBufferGeometry args={[990, 64, 64]} />
          <meshBasicMaterial
            side={THREE.BackSide}
          >
          <videoTexture
              attach="map"
              args={[video]}
              anisotropy={16}
              format={THREE.RGBFormat}
              encoding={THREE.sRGBEncoding}
              magFilter={THREE.LinearFilter}
              minFilter={THREE.LinearFilter}
            />
          </meshBasicMaterial>
        </mesh>
      </group>
    )
  } else {
    return null;
  }
}

const ThreeSixtyVideo = (props) => {

  const { url, caption, isFullBleed } = props;
  const video = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const handleClick = () => {
    if (video?.current?.paused === true) {
      video.current.play()
        .catch((error) => {
          console.log(error);
        });
    }
  }

  useEffect(() => {
    if (video.current) {
      if (video?.current?.paused === true) {
        video.current.play()
          .then(() => {
            setIsPlaying(true);
          })
          .catch((error) => { console.log(error); });
      } else {
        setIsPlaying(true);
      }
    }
  }, []);

  return (
    <div className="three-sixty-video__outer">
      {
        caption &&
        <p
          className={`video__caption${isFullBleed === true ? ' full-bleed' : ''}`}
        >{caption}</p>
      }
      <div
        className="three-sixty-video__wrapper"
        onClick={handleClick}
      >
        {
          video.current && isPlaying === true ?
          <Canvas
            gl={{ antialias: true }}
            onCreated={({ gl }) => {
              gl.toneMapping = THREE.ACESFilmicToneMapping;
              gl.outputEncoding = THREE.sRGBEncoding
            }}
          >
            {
              <Suspense fallback={null}>
                <ThreeSixtyVideoScene video={video.current} />
              </Suspense>
            }
            <OrbitControls enablePan={false} enableZoom={false} />
            </Canvas>
            :
            <div
              className="three-sixty-video__placeholder"
            onClick={handleClick}/>
        }
        <video
          crossOrigin="anonymous"
          loop
          muted
          src={url}
          ref={video}
          playsInline={true}
          allowFullScreen={false}
          autoPlay
          onPlay={() => { setIsPlaying(true); }}
          onPause={() => { setIsPlaying(false); }}
        />
        <img className="icon--360" src={rotate3dicon} alt="360 rotate icon" />
      </div>
    </div>
  )
}

export default ThreeSixtyVideo;