import { Route, Routes } from 'react-router-dom';
import Menu from './components/Menu.js';
import MenuMobile from './components/MenuMobile.js';
import LandingPage from './views/LandingPage.js';
import Work from './views/Work.js';
import About from './views/About.js';
import Play from './views/Play.js';
import Project from './views/Project.js';
import Contact from './views/Contact.js';
import { AnimatePresence } from 'framer-motion';
import Header from './components/Header';
import ProjectPreview from './views/ProjectPreview';
import PlayPreview from './views/PlayPreview';

const Content = (props) => {

  const { workData, windowDimensions, onFirstTouch, pageName, handleScrollThrottled, menuItemIsActive, menuIsOpen,
    wrapper, homeSection, workSection, aboutSection, contactSection, playSection, activeTitleText, location } = props;
  const { windowWidth, windowHeight } = windowDimensions;

  if (props.location.pathname === '/preview/work') {
    return <ProjectPreview {...props} />;
  } else if (props.location.pathname === '/preview/play') {
    return <PlayPreview {...props} />
  } else {
    return (
      <div
        className={`App view--${pageName && pageName !== '' ? pageName : 'home'} full-height${window.USER_IS_TOUCHING === true ? ' user-is-touching' : ''}`}
        onTouchStart={onFirstTouch}

        style={{
          height: windowHeight + 'px'
        }}
      >
        {
          windowWidth >= 768 && activeTitleText !== '' &&
          <h2 className="active-title">{activeTitleText}</h2>
        }
        <AnimatePresence>
          {
            props.location.pathname !== '/' &&
            <Header {...props} />
          }
        </AnimatePresence>
        <div
          className={`sections__wrapper${menuIsOpen === true ? ' sections__wrapper--menu--open' : ''}${menuItemIsActive === true ? ' sections__wrapper--menu-item--active' : ''}`}
          onScroll={handleScrollThrottled}
          ref={wrapper}
        >
          <section
            className="section--route section--route--home"
            ref={homeSection}
          >
            <LandingPage {...props} />
          </section>
          <section
            className="section--route section--route--work"
            ref={workSection}
          >
            <Work {...props} />
          </section>
          <section
            className="section--route section--route--about"
            ref={aboutSection}
          >
            <About {...props} />
          </section>
          <section
            className="section--route section--route--contact"
            ref={contactSection}
          >
            <AnimatePresence>
              <Contact {...props} />
            </AnimatePresence>
          </section>
          <section
            className="section--route section--route--play"
            ref={playSection}
            style={{
              width: '100%',
              height: windowDimensions.windowHeight * 2 + 'px'
            }}
          >
          </section>
        </div>
        <AnimatePresence>
          <Routes key={location.pathname}>
            <Route
              exact
              path="/play"
              element={<Play {...props} />}
            />
            {
              workData.entries &&
              workData.entries[0] &&
              workData.entries.map(
                (item, i) => (
                  <Route
                    path={`/work/${item.client_slug ? `${item.client_slug}/${item.title_slug}` : item.title_slug}`}
                    key={i}
                    element={<Project
                        {...props}
                        index={i}
                        item={item}
                      />}
                  />
                )
              )
            }
            <Route path={'*'} element={<div />} />
          </Routes>
        </AnimatePresence>
        {
          windowWidth >= 768 ?
            <Menu {...props} />
            :
            <MenuMobile {...props} />
        }
      </div>
    );
  }
}

export default Content;