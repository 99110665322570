import LazyloadImage from '../LazyloadImage';

const ProjectGalleryImage = (props) => {

  const { image } = props;

  return (
    <div
      className="project__gallery__image__wrapper"
      style={{
        width: image.width + '%'
      }}
    >
      <LazyloadImage {...props} image={image} maxWidth={480} maxHeight={640} />
    </div>
  )
}

export default ProjectGalleryImage;