import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

const Menu = (props) => {

  const { windowDimensions, setMenuItemIsActive, menuIsOpen, setMenuIsOpen, workSection, aboutSection, playSection, contactSection, wrapper } = props;
  const { windowWidth } = windowDimensions;

  const handleClickItem = (e, section) => {
    if (windowWidth < 768) {
      if (menuIsOpen === false) {
        e.preventDefault();
      }
    }
    setMenuItemIsActive(false);
    setMenuIsOpen(false);
    if (section.current && wrapper.current) {
      wrapper.current.scrollTo({
        left: 0,
        top: section.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }

  return (
    <Fragment>
      <button className="menu-button--mobile" onClick={() => setMenuIsOpen(!menuIsOpen)} aria-label={`${menuIsOpen === false ? 'Open' : 'Close'} the menu`} />
      <AnimatePresence>
      {
        menuIsOpen === true &&
          <motion.nav
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={`menu--mobile`}
          >
          <ul className="menu--mobile__list">
            <li className="menu__item--mobile">
              <Link
                onClick={(e) => handleClickItem(e, workSection)}
                onMouseEnter={() => { setMenuItemIsActive(true); }}
                onMouseLeave={() => { setMenuItemIsActive(false); }}
                onBlur={() => { setMenuItemIsActive(false); }}
                to="/work" className={`menu__item--mobile__link font--pilowlava`}
              >
                Work
              </Link>
            </li>
            <li className="menu__item--mobile">
              <Link
                onClick={(e) => handleClickItem(e, aboutSection)}
                onMouseEnter={() => { setMenuItemIsActive(true); }}
                onMouseLeave={() => { setMenuItemIsActive(false); }}
                onBlur={() => { setMenuItemIsActive(false); }}
                to="/about" className={`menu__item--mobile__link font--lack`}
              >
                About
              </Link>
            </li>
            <li className="menu__item--mobile">
              <Link
                onClick={(e) => handleClickItem(e, contactSection)}
                onMouseEnter={() => { setMenuItemIsActive(true); }}
                onMouseLeave={() => { setMenuItemIsActive(false); }}
                onBlur={() => { setMenuItemIsActive(false); }}
                to="/contact" className={`menu__item--mobile__link font--voyage`}
              >
                Contact
              </Link>
            </li>
            <li className="menu__item--mobile">
              <Link
                onClick={(e) => handleClickItem(e, playSection)}
                onMouseEnter={() => { setMenuItemIsActive(true); }}
                onMouseLeave={() => { setMenuItemIsActive(false); }}
                onBlur={() => { setMenuItemIsActive(false); }}
                to={`/play`} className={`menu__item--mobile__link font--chaney`}
              >
                2k{new Date().getFullYear().toString().substring(2)}
              </Link>
            </li>
          </ul>
        </motion.nav>
      }
      </AnimatePresence>
    </Fragment>
  )
}

export default Menu;