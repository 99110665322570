import { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import folderIcon from '../../../assets/folder.png';
import VideoPlayer from "../VideoPlayer";
import LazyloadImage from '../LazyloadImage';
import ReactMarkdown from 'react-markdown';

const PlayFinderText = (props) => {

  const { value } = props;

  return (
    <div className="modal--folder__text">
      <h3 className="modal--folder__text__heading">{value.title}</h3>
      <ReactMarkdown className="modal--folder__text__copy">{value.text}</ReactMarkdown>
    </div>
  )
}

const PlayFinderVideo = (props) => {

  const { value } = props;

  return (
    <div className="modal--folder__image__wrapper">
      <VideoPlayer url={value.video} isLooping={true} noControls={true} />
    </div>
  )
}

const PlayFinderLink = (props) => {

  const { value } = props;

  return (
    <div>
      <a href={value.url} rel="noreferrer" target="_blank">{value.title}</a>
    </div>
  )
}

const PlayFinderImage = (props) => {

  const { value } = props;

  if (value.url) {
    return (
      <div className="modal--folder__image__wrapper">
        <a className="modal__image__link" href={value.url} rel="noreferrer" target="_blank">
          <LazyloadImage {...props} image={value.image} />
        </a>
      </div>
    )
  } else {
    return (
      <div className="modal--folder__image__wrapper">
        <LazyloadImage {...props} image={value.image} />
      </div>
    )
  }
}

const PlayFinderFolder = (props) => {

  const { value } = props;
  const { contents } = value;
  const [openIndex, setOpenIndex] = useState(-1);

  return (
    <div>
      {
        contents &&
        contents[0] &&
        contents.map(
          (item, i) => (
            <PlayFinderItem key={i} index={i} windowItem={item} openIndex={openIndex} setOpenIndex={setOpenIndex} />
          )
        )
      }
    </div>
  )
}

const PlayFinderItem = (props) => {

  const { windowItem } = props;
  const { field, value } = windowItem;

  if (field.name === 'image_link') {
    return <PlayFinderImage value={value} {...props} />
  } else if (field.name === 'link') {
    return <PlayFinderLink value={value} {...props} />
  } else if (field.name === 'folder') {
    return <PlayFinderFolder value={value} {...props} />
  } else if (field.name === 'image') {
    return <PlayFinderImage value={value} {...props} />
  } else if (field.name === 'video') {
    return <PlayFinderVideo value={value} {...props} />
  } else if (field.name === 'text') {
    return <PlayFinderText value={value} {...props} />
  } else {
    return null;
  }
}

const PlayFinderListItem = (props) => {

  const { index, windowItem, openIndex, setOpenIndex } = props;

  return (
    <li
      className={`modal--folder__content__inner__folder__list__item${index === openIndex ? ' open' : ''}`}
    >
      <button
        onClick={() => {
          if (index === openIndex) {
            setOpenIndex(-1);
          } else {
            setOpenIndex(index);
          }
        }}
        className="modal--folder__content__inner__folder__list__item__button"
      >
        <img className="modal--folder__content__inner__folder__list__item__button__icon" src={folderIcon} alt="folder icon" />
        <p className="modal--folder__content__inner__folder__list__item__button__title">{windowItem.value.title}</p>
        <div className="modal--folder__content__inner__folder__list__item__button__arrow" />
      </button>
    </li>
  )
}

const PlayFinderWindow = (props) => {

  const { container, item, windowContents, windowDimensions, modal, handleMouseDown, location, isPreview, handleCloseWindow } = props;
  const { pathname } = location;
  const { windowWidth, windowHeight } = windowDimensions;
  const [openIndex, setOpenIndex] = useState(0);

  useEffect(() => {
    if (modal.current !== null) {
      if (modal.current.querySelector('.modal__inner')) {
        modal.current.querySelector('.modal__inner').style.height = 'auto';
        setTimeout(() => {
          if (modal.current) {
            modal.current.querySelector('.modal__inner').style.height = modal.current.offsetHeight + 'px';
          }
        }, 200);
      }
    }
  }, [openIndex, modal]);

  if (item.shown === true) {
    return (
      <motion.div
        className="modal modal--folder play__modal"
        ref={modal}
        drag={isPreview === true ? false : true}
        dragElastic={false}
        dragMomentum={false}
        dragConstraints={container}
        style={{
          opacity: item.shown === true ? '1' : '0',
          pointerEvents: item.shown === true ? 'auto' : 'none',
          zIndex: item.index + 1,
          left: isPreview === true ? item.x / 100 * windowWidth + 'px' : null,
          top: isPreview === true ? item.y / 100 * windowHeight + 'px' : null
        }}
        onMouseDown={() => { handleMouseDown(item); }}
        onTouchStart={() => { handleMouseDown(item); }}
        initial={{
          x: isPreview === true ? 0 : item.x / 100 * windowWidth,
          y: isPreview === true ? 0 : item.y / 100 * windowHeight
        }}
        animate={{
          x: isPreview === true ? 0 : item.x / 100 * windowWidth,
          y: isPreview === true ? 0 : item.y / 100 * windowHeight
        }}
        transition={{
          duration: 0.4,
          delay: pathname !== '/play' ? 0 : 0.4
        }}
      >
        <div className="modal__inner modal--folder__inner">
          <div className="modal__header modal--folder__header">
            <div className="modal__header__inner">
              <div className="modal__header__buttons">
                <button
                  className="folder__header__close modal__header__button modal__header__button--close"
                  onClick={handleCloseWindow}
                ></button>
                <button
                  className="folder__header__minimise modal__header__button modal__header__button--minimise"
                  onClick={handleCloseWindow}
                ></button>
                <div className="folder__header__fullscreen modal__header__button modal__header__button--fullscreen" />
              </div>
              <h2 className="modal__header__title">{item.title}</h2>
            </div>
          </div>
          <div
            className="modal__content modal--folder__content"
          >
            <div className="modal--folder__content__inner">
              <div className="modal--folder__content__inner__folder">
                <ul className="modal--folder__content__inner__folder__list">
                  {
                    windowContents &&
                    windowContents[0] &&
                    windowContents.map(
                      (windowItem, i) => (
                        <PlayFinderListItem key={i} index={i} windowItem={windowItem} openIndex={openIndex} setOpenIndex={setOpenIndex} />
                      )
                    )
                  }
                </ul>
                <div className="modal--folder__content__inner__folder__resizer"></div>
              </div>
              <div className="modal--folder__content__inner__item--open">
                {
                  openIndex !== -1 &&
                  windowContents &&
                  windowContents[0] &&
                  windowContents.map(
                    (windowItem, i) => (
                      openIndex === i &&
                      <PlayFinderItem key={i} index={i} windowItem={windowItem} openIndex={openIndex} setOpenIndex={setOpenIndex} />
                    )
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  } else {
    return null;
  }
}

export default PlayFinderWindow;